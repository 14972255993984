<template>
    <div class="vote-index">
        <div class="vote-content">
            <img src="../../static/image/vote/index_01.png"/>
            <img src="../../static/image/vote/index_02.png"/>
            <img src="../../static/image/vote/index_03.png"/>
            <img src="../../static/image/vote/index_04.png"/>
            <img src="../../static/image/vote/index_05.png"/>
            <img src="../../static/image/vote/index_06.png"/>
            <img src="../../static/image/vote/index_07.png"/>
            <img @click="toForm" src="../../static/image/vote/index_08.png"/>
            <img src="../../static/image/vote/index_09.png"/>
            <img src="../../static/image/vote/index_10.png"/>
            <img src="../../static/image/vote/index_11.png"/>
            <img src="../../static/image/vote/index_12.png"/>
        </div>
    </div>
</template>

<script>
    import initWXAPI from "../../utils/initWXAPI";
    import WXShare from "../../utils/WXShare";

    export default {
        name: "Index",
        data() {
            return {}
        },
        methods: {
            toForm() {
                window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf38126d81238fc63&redirect_uri=https://redsw1.02lu.com/voteFrom&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect'
            },
            initPageShare() {
                initWXAPI.init(this.$store.state.url, function () {
                    const options = {
                        title: '红色故事演讲大赛',
                        desc: '2022上海市民文化节',
                        imgUrl: 'https://redsw1.02lu.com/share_logo.png'
                    }
                    WXShare.initWXShare(options)
                    WXShare.initWXTimelineShare(options)
                })
            }
        },
        created() {
            this.initPageShare();
        }
    }
</script>

<style scoped lang="scss">
    @import "../../assets/scss/reset";

    .vote-index {
        min-height: 100vh;

        .vote-content {
            font-size: 0;

            img {
                width: 100%;
            }
        }
    }
</style>